import React, { useState, useContext } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "./AuthProvider"; // Import the AuthContext

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { login } = useContext(AuthContext); // Access the login function from AuthContext

  // Function to fetch preferences key/values and store them in localStorage
  const fetchPreferences = async (token) => {
    try {
      const response = await fetch("/mnm-api/preferences/distinct-key-values", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in Authorization header
        },
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("preferencesKeyValues", JSON.stringify(data)); // Store in localStorage
        console.log("Fetched preferences key/values:", data);
      } else {
        throw new Error("Failed to fetch preferences");
      }
    } catch (error) {
      console.error("Error fetching preferences:", error);
    }
  };

  // Function to fetch aboutMe key/values and store them in localStorage
  const fetchAboutMe = async (token) => {
    try {
      const response = await fetch("/mnm-api/aboutme/distinct-key-values", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in Authorization header
        },
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("aboutMeKeyValues", JSON.stringify(data)); // Store in localStorage
        console.log("Fetched aboutMe key/values:", data);
      } else {
        throw new Error("Failed to fetch aboutMe data");
      }
    } catch (error) {
      console.error("Error fetching aboutMe data:", error);
    }
  };

  const handleLogin = async () => {
    const loginData = {
      username: email,
      password: password,
    };

    try {
      const response = await fetch("/mnm-api/public/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        const userId = data.id;
        const userData = { id: userId, gender: data.gender }; // Store additional user details if needed

        // Call the login function from AuthContext
        login(token, userId, userData);

        console.log("Login successful:", data);

        // Fetch and store preferences and aboutMe key/values after login
        await fetchPreferences(token);
        await fetchAboutMe(token);

        // Navigate to the profile page after successful login
        navigate("/sidebar");
      } else {
        throw new Error("Invalid email or password");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage(error.message);
    }
  };

  const handleGoogleLogin = () => {
    // Implement Google login logic if needed
  };

  const continueAsGuest = () => {
    navigate("/sidebar"); // Navigate to the dashboard as guest
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      {errorMessage && (
        <Typography variant="body1" color="error">
          {errorMessage}
        </Typography>
      )}
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="Password"
        variant="outlined"
        type="password"
        fullWidth
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleLogin}
      >
        Login
      </Button>

      <Typography variant="h6" align="center" margin="normal">
        or
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={handleGoogleLogin}
      >
        Login with Google
      </Button>

      <Typography variant="h6" align="center" margin="normal">
        or
      </Typography>

      <Button
        variant="contained"
        color="default"
        fullWidth
        onClick={continueAsGuest}
      >
        Continue as Guest
      </Button>

      <Typography variant="body2" align="center" margin="normal">
        Don't have an account? <Link to="/signup">Sign up</Link>
      </Typography>
    </Container>
  );
};

export default Login;
