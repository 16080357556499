import React, { createContext, useState, useEffect } from "react";

// Create the Auth Context
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if user is already logged in by checking token in localStorage
    const token = localStorage.getItem("jwtToken");
    const userId = localStorage.getItem("userId");
    if (token && userId) {
      setIsAuthenticated(true);
      setUser({ id: userId }); // You can store additional user details as needed
    }
  }, []);

  const login = (token, userId, userData) => {
    // Save token and user details, set user as authenticated
    localStorage.setItem("jwtToken", token);
    localStorage.setItem("userId", userId);
    setIsAuthenticated(true);
    setUser(userData); // You can store actual user info here
  };

  const logout = () => {
    // Remove token and user details
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userId");
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
