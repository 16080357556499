import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  IconButton,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const visibilityOptions = ["Public", "Contacted"];

const MatchingProfiles = () => {
  // State to hold the matching profiles
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [fetched, setFetched] = useState(false); // To track if profiles have been fetched
  const [selectedProfile, setSelectedProfile] = useState(null); // Store selected profile data

  // Fetch matching profiles from the API
  const fetchProfiles = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("jwtToken");
      const userId = localStorage.getItem("userId");

      const response = await fetch(`/mnm-api/matchmaking/matches/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch matching profiles");
      }

      const data = await response.json();
      setProfiles(data);
      setFetched(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch user profile by userId
  const fetchUserProfile = async (userId) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("jwtToken");

      const response = await fetch(`/mnm-api/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }

      const profileData = await response.json();
      setSelectedProfile(profileData); // Store fetched profile data
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Show loading state while fetching profiles
  if (loading) {
    return <CircularProgress />;
  }

  // Show error if there's an issue with the API request
  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Matching Profiles
      </Typography>

      {/* Button to trigger fetching of profiles */}
      <Button
        variant="contained"
        color="primary"
        onClick={fetchProfiles}
        disabled={loading}
      >
        Fetch Matches
      </Button>

      {/* Conditionally show profiles if they have been fetched */}
      {fetched && (
        <div style={{ marginTop: "20px" }}>
          {profiles.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User ID</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {profiles.map((profile) => (
                    <TableRow key={profile.userId}>
                      <TableCell>{profile.userId}</TableCell>
                      <TableCell>{profile.email}</TableCell>
                      <TableCell>
                        {/* Action button to fetch profile based on userId */}
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => fetchUserProfile(profile.userId)}
                        >
                          Fetch Profile
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No matching profiles found.</Typography>
          )}

          {/* Display selected profile details */}
          {selectedProfile && (
            <div style={{ marginTop: "20px" }}>
              <Typography variant="h6">Selected Profile Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Name:</strong> {selectedProfile.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Age:</strong> {selectedProfile.age}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Email:</strong> {selectedProfile.email}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Gender:</strong> {selectedProfile.gender}
                  </Typography>
                </Grid>
              </Grid>

              {/* Display About Me section */}
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                About Me
              </Typography>
              {selectedProfile.aboutMe.map((item, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={4}>
                    <Typography>
                      <strong>Key:</strong> {item.key}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <strong>Value:</strong> {item.value}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <strong>Visibility:</strong> {item.visibility}
                    </Typography>
                  </Grid>
                </Grid>
              ))}

              {/* Display Profile Photos */}
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                Profile Photos
              </Typography>
              <Grid container spacing={2}>
                {selectedProfile.profilePhotos.map((photo, index) => (
                  <Grid item xs={4} key={index}>
                    <img
                      src={photo.photoUrl}
                      alt={`User Photo ${index + 1}`}
                      width="100%"
                      style={{ marginBottom: "10px" }}
                    />
                    <Typography>Visibility: {photo.visibility}</Typography>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MatchingProfiles;
