import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
} from "@mui/material";

// Mock WebSocket for simplicity
const mockOnlineUsers = ["Alice", "Bob", "Charlie", "David"]; // Mock online users

const Messages = () => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // The user we are chatting with
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    // Simulate fetching online users from server or WebSocket
    setOnlineUsers(mockOnlineUsers);
  }, []);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setMessages([]); // Clear previous chat messages when a new user is selected
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      const message = { sender: "Me", text: newMessage, timestamp: new Date() };
      setMessages((prevMessages) => [...prevMessages, message]);
      setNewMessage(""); // Clear input field
    }
  };

  return (
    <Box sx={{ display: "flex", height: "80vh" }}>
      {/* Sidebar with Online Users */}
      <Box sx={{ width: "25%", borderRight: "1px solid #ddd", padding: 2 }}>
        <h3>Online Users</h3>
        <List>
          {onlineUsers.map((user, index) => (
            <ListItem button key={index} onClick={() => handleUserSelect(user)}>
              <ListItemText primary={user} />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Chat Window */}
      <Box
        sx={{
          width: "75%",
          padding: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {selectedUser ? (
          <>
            <h3>Chat with {selectedUser}</h3>
            <Box
              sx={{
                flexGrow: 1,
                border: "1px solid #ddd",
                padding: 2,
                overflowY: "auto",
              }}
            >
              {messages.length === 0 ? (
                <p>No messages yet. Start chatting!</p>
              ) : (
                messages.map((msg, index) => (
                  <Box key={index} sx={{ marginBottom: 1 }}>
                    <strong>{msg.sender}</strong>: {msg.text}{" "}
                    <small>({msg.timestamp.toLocaleTimeString()})</small>
                  </Box>
                ))
              )}
            </Box>

            {/* Message Input */}
            <Box sx={{ display: "flex", marginTop: 2 }}>
              <TextField
                label="Type a message"
                variant="outlined"
                fullWidth
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendMessage}
                sx={{ marginLeft: 2 }}
              >
                Send
              </Button>
            </Box>
          </>
        ) : (
          <p>Select a user to start chatting.</p>
        )}
      </Box>
    </Box>
  );
};

export default Messages;
