import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Grid, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Autocomplete from "@mui/material/Autocomplete";

const PreferencesForm = () => {
  const [preferences, setPreferences] = useState([
    { key: "", value: "", distinctValues: [] },
  ]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true); // Disable save initially
  const [predefinedKeys, setPredefinedKeys] = useState([]); // Keys with distinct values

  // Fetch predefined keys and values from localStorage
  useEffect(() => {
    const storedAboutMeKeys =
      JSON.parse(localStorage.getItem("aboutMeKeyValues")) || [];
    setPredefinedKeys(storedAboutMeKeys); // Set the predefined keys and their distinct values
  }, []);

  // Handle changes to individual preference fields
  const handlePreferenceChange = (index, field, value) => {
    const updatedPreferences = [...preferences];
    updatedPreferences[index][field] = value;

    // If key changes, update distinctValues based on selected key
    if (field === "key") {
      const selectedKey = predefinedKeys.find((item) => item.id === value);
      updatedPreferences[index].distinctValues = selectedKey
        ? selectedKey.distinctValues
        : [];
      updatedPreferences[index].value = ""; // Clear value when key changes
    }

    setPreferences(updatedPreferences);
  };

  const addPreferenceField = () => {
    setPreferences([
      ...preferences,
      { key: "", value: "", distinctValues: [] },
    ]);
  };

  const removePreferenceField = (index) => {
    const updatedPreferences = preferences.filter((_, i) => i !== index);
    setPreferences(updatedPreferences);
  };

  // Fetch the saved preferences and populate the form
  const showPreferences = () => {
    const token = localStorage.getItem("jwtToken");
    const userId = localStorage.getItem("userId");

    fetch(`/mnm-api/preferences`, {
      method: "POST", // Use POST to send userId in the body
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ userId: userId }), // Send userId in the request body
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch preferences");
        }
        return response.json();
      })
      .then((data) => {
        if (data.preferences && data.preferences.length > 0) {
          setPreferences(
            data.preferences.map((pref) => ({
              key: pref.key,
              value: pref.value,
              distinctValues:
                predefinedKeys.find((item) => item.id === pref.key)
                  ?.distinctValues || [], // Set distinct values based on the key
            }))
          );
        } else {
          // If no preferences are returned, clear the form and enable save
          setPreferences([{ key: "", value: "", distinctValues: [] }]);
        }
        setIsSaveDisabled(false); // Enable the save button
      })
      .catch((error) => {
        console.error("Error fetching preferences:", error);
        alert("Error fetching preferences");
      });
  };

  // Validate that all preferences have both key and value before submitting
  const validatePreferences = () => {
    for (const pref of preferences) {
      if (!pref.key || !pref.value) {
        return false;
      }
    }
    return true;
  };

  // Save the preferences to the backend (replace instead of merging)
  const handleSubmit = () => {
    if (!validatePreferences()) {
      alert("All preferences must have both key and value");
      return;
    }

    const token = localStorage.getItem("jwtToken");
    const userId = localStorage.getItem("userId");
    const gender = localStorage.getItem("gender"); // Fetch gender from local storage

    const preferencesData = {
      userId: userId,
      gender: gender, // Include gender in the preferences data
      preferences: preferences, // Save the preferences array as it is
    };

    fetch(`/mnm-api/preferences/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(preferencesData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save preferences");
        }
        return response.json();
      })
      .then((data) => {
        alert("Preferences saved successfully!");
        showPreferences(); // Fetch updated preferences
      })
      .catch((error) => {
        console.error("Error saving preferences:", error);
        alert("Error saving preferences");
      });
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Add Your Preferences
      </Typography>

      {preferences.map((preference, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={5}>
            <Autocomplete
              freeSolo
              options={predefinedKeys.map((key) => key.id)} // Use keys from local storage
              value={preference.key}
              onInputChange={(event, newValue) =>
                handlePreferenceChange(index, "key", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Key"
                  variant="outlined"
                  fullWidth
                  onChange={(e) =>
                    handlePreferenceChange(index, "key", e.target.value)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Autocomplete
              freeSolo
              options={preference.distinctValues} // Show distinct values for the selected key
              value={preference.value}
              onInputChange={(event, newValue) =>
                handlePreferenceChange(index, "value", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Value"
                  variant="outlined"
                  fullWidth
                  onChange={(e) =>
                    handlePreferenceChange(index, "value", e.target.value)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => removePreferenceField(index)}>
              <RemoveCircleOutlineIcon color="secondary" />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={addPreferenceField}
        color="primary"
        style={{ marginTop: "10px" }}
      >
        Add Preference
      </Button>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit}
        style={{ marginTop: "20px" }}
        disabled={isSaveDisabled} // Disable until Show Preferences is clicked
      >
        Save Preferences
      </Button>

      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={showPreferences}
        style={{ marginTop: "20px" }}
      >
        Show Preferences
      </Button>
    </div>
  );
};

export default PreferencesForm;
