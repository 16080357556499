import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Drawer,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import {
  Home,
  Luggage,
  Settings,
  ModeNightRounded,
  Pages,
  ExitToApp,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery"; // For mobile detection

const Sidebar = ({ mode, setMode, roles }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)"); // Detect mobile screen

  const [selectedBranch, setSelectedBranch] = useState("");
  const navigate = useNavigate(); // Initialize navigate

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
    localStorage.setItem("branchCode", event.target.value);
  };

  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("jwtToken");
    navigate("/login");
  };

  const menuItems = [
    {
      label: "Dashboard",
      icon: <Home />,
      link: "/dashboard",
      roles: ["admin", "user"],
    },
    { label: "Profile", icon: <Pages />, link: "/profile", roles: ["user"] },
    {
      label: "Preference",
      icon: <Pages />,
      link: "/preferences",
      roles: ["admin", "user"],
    },
    { label: "Matches", icon: <Luggage />, link: "/matches", roles: ["user"] },
    {
      label: "Messages",
      icon: <Luggage />,
      link: "/messages",
      roles: ["user"],
    },
    {
      label: "Find Profiles",
      icon: <Settings />,
      link: "/userprofile",
      roles: ["user", "manager", "admin"],
    },
    {
      label: "Help",
      icon: <Settings />,
      link: "/help",
      roles: ["user", "admin", "manager"],
    },
    {
      label: "Logout",
      icon: <ExitToApp />,
      action: handleLogout,
      roles: ["admin", "user", "manager"],
    },
  ];

  const drawerContent = (
    <List>
      {menuItems.map((item, index) => {
        if (item.roles.some((role) => roles.includes(role))) {
          return item.action ? (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={item.action}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem key={index} disablePadding>
              <ListItemButton component={Link} to={item.link}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          );
        }
        return null;
      })}
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => setMode(mode === "light" ? "dark" : "light")}
        >
          <ListItemIcon>
            <ModeNightRounded sx={{ color: "#ffe3a3" }} />
          </ListItemIcon>
          <Switch checked={mode === "dark"} />
        </ListItemButton>
      </ListItem>
    </List>
  );

  // Mobile Card Menu
  const cardMenu = (
    <Grid container spacing={2} justifyContent="center">
      {menuItems.map((item, index) => {
        if (item.roles.some((role) => roles.includes(role))) {
          return (
            <Grid item xs={6} key={index}>
              <Card
                onClick={item.action ? item.action : () => navigate(item.link)}
                sx={{
                  cursor: "pointer",
                  backgroundColor: "#21295c",
                  color: "#ffe3a3",
                }}
              >
                <CardContent>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <Typography>{item.label}</Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {/* Show Drawer for Desktop, Card Menu for Mobile */}
      {isMobile ? (
        <Box sx={{ width: "100%", padding: "20px" }}>
          <Typography variant="h5" gutterBottom>
            Menu
          </Typography>
          {cardMenu}
        </Box>
      ) : (
        <>
          {/* Mobile Menu Toggle */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" }, color: "#ffe3a3" }}
          >
            <MenuIcon />
          </IconButton>

          {/* Temporary Drawer for Mobile */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }} // Better open performance on mobile.
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: 240,
                backgroundColor: "#21295c",
                color: "#ffe3a3",
              },
            }}
          >
            {drawerContent}
          </Drawer>

          {/* Permanent Drawer for Desktop */}
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: 240,
                backgroundColor: "#21295c",
                color: "#ffe3a3",
              },
            }}
            open
          >
            {drawerContent}
          </Drawer>
        </>
      )}
    </Box>
  );
};

export default Sidebar;
