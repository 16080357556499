import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import useMediaQuery from "@mui/material/useMediaQuery"; // To check screen size

const visibilityOptions = ["Public", "Contacted"];
const genderOptions = ["Male", "Female", "Other"]; // Gender options

const ProfileForm = () => {
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [aboutMeKeys, setAboutMeKeys] = useState([]); // List of keys fetched from localStorage
  const [aboutMe, setAboutMe] = useState([]); // Initially empty list to store existing user data
  const [photos, setPhotos] = useState([]); // Array to hold photos (base64 and visibility)
  const [fetchingPreferences, setFetchingPreferences] = useState(false); // Track fetching state

  // Responsive breakpoints for mobile adjustments
  const isMobile = useMediaQuery("(max-width:600px)");

  // Load preferences and aboutMeKeys from localStorage
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    const userId = localStorage.getItem("userId");

    // Fetch stored data from localStorage
    const storedAboutMeKeys = JSON.parse(
      localStorage.getItem("aboutMeKeyValues")
    );
    if (storedAboutMeKeys) {
      setAboutMeKeys(storedAboutMeKeys); // Store aboutMe keys from localStorage
    }

    // Fetch user profile data
    fetch(`/mnm-api/users/${userId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`, // Pass the JWT token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUserId(data.id || "");
        setName(data.username || "");
        setAge(data.age ? String(data.age) : ""); // Ensure age is set as a string
        setGender(data.gender || ""); // Set the gender
        setAboutMe(
          data.aboutMe.map((item) => ({
            key: item.key,
            value: item.value,
            visibility: item.visibility || "Public",
            distinctValues:
              storedAboutMeKeys.find((key) => key.id === item.key)
                ?.distinctValues || [],
          }))
        );
        // Set profile photos, converting base64 to preview URL
        setPhotos(
          (data.profilePhotos || []).map((photo) => ({
            base64: photo.photoUrl, // Correct field name is `photoUrl` not `base64`
            preview: `data:image/jpeg;base64,${photo.photoUrl}`, // Use the correct field to generate preview
            visibility: photo.visibility || "Public", // Set default visibility
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching profile:", error);
      });
  }, []);

  // Handle About Me change
  const handleAboutMeChange = (index, field, value) => {
    const updatedAboutMe = [...aboutMe];
    updatedAboutMe[index][field] = value;

    // If key changes, update distinctValues based on selected key
    if (field === "key") {
      const selectedKey = aboutMeKeys.find((item) => item.id === value);
      updatedAboutMe[index].distinctValues = selectedKey
        ? selectedKey.distinctValues
        : [];
      updatedAboutMe[index].value = ""; // Clear value when key changes
    }

    setAboutMe(updatedAboutMe);
  };

  // Add new About Me field
  const addAboutMeField = () => {
    setAboutMe([
      ...aboutMe,
      { key: "", value: "", visibility: "Public", distinctValues: [] },
    ]);
  };

  // Remove About Me field
  const removeAboutMeField = (index) => {
    const updatedAboutMe = aboutMe.filter((_, i) => i !== index);
    setAboutMe(updatedAboutMe);
  };

  // Convert file to base64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const uploadPromises = acceptedFiles.map((file) =>
        getBase64(file).then((base64) => ({
          file,
          preview: URL.createObjectURL(file), // For preview purposes
          base64: base64, //.split(",")[1], 
          visibility: "Public",
        }))
      );

      Promise.all(uploadPromises).then((uploadedFiles) => {
        setPhotos((prevPhotos) => [...prevPhotos, ...uploadedFiles]);
      });
    },
  });

  // Handle changes to photo visibility
  const handlePhotoVisibilityChange = (index, visibility) => {
    const updatedPhotos = [...photos];
    updatedPhotos[index].visibility = visibility;
    setPhotos(updatedPhotos);
  };

  // Handle deleting a photo
  const handleDeletePhoto = (index) => {
    const updatedPhotos = photos.filter((_, i) => i !== index);
    setPhotos(updatedPhotos);
  };

  // Handle form submission
  const handleSubmit = async () => {
    // Prepare the profile data including photos in base64
    const profileData = {
      id: userId,
      username: name,
      age: Number(age), // Ensure age is submitted as a number
      gender,
      aboutMe,
      profilePhotos: photos.map((photo) => ({
        photoUrl: photo.base64,
        visibility: photo.visibility,
      })), // Include only base64 and visibility
    };

    const token = localStorage.getItem("jwtToken");

    fetch(`/mnm-api/users/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(profileData),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(
              `Failed to save profile: ${data.message || "Unknown error"}`
            );
          });
        }
        return response.json();
      })
      .then(() => {
        alert("Profile saved successfully!");
      })
      .catch((error) => {
        alert(`Error saving profile: ${error.message}`);
      });
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Complete Your Profile
      </Typography>

      {/* Name Field */}
      <TextField
        label="Name"
        variant="outlined"
        fullWidth
        margin="normal"
        value={name}
        onChange={(e) => setName(e.target.value)}
        inputProps={{ style: { fontSize: isMobile ? "16px" : "14px" } }} // Mobile-friendly input size
      />

      {/* Age Field */}
      <TextField
        label="Age"
        variant="outlined"
        fullWidth
        margin="normal"
        type="number"
        value={age}
        onChange={(e) => setAge(e.target.value)}
        inputProps={{ style: { fontSize: isMobile ? "16px" : "14px" } }} // Mobile-friendly input size
      />

      {/* Gender Field */}
      <FormControl fullWidth margin="normal">
        <InputLabel style={{ fontSize: isMobile ? "16px" : "14px" }}>
          Gender
        </InputLabel>
        <Select
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          label="Gender"
          disabled={fetchingPreferences}
          style={{ fontSize: isMobile ? "16px" : "14px" }} // Mobile-friendly select size
        >
          {genderOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* About Me Section */}
      <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
        About Me
      </Typography>

      {aboutMe.map((item, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={12} sm={4}>
            {/* Editable Key */}
            <Autocomplete
              freeSolo
              options={aboutMeKeys.map((key) => key.id)} // Show all keys
              value={item.key}
              onInputChange={(e, newValue) =>
                handleAboutMeChange(index, "key", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Key"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {/* Editable Value */}
            <Autocomplete
              freeSolo
              options={item.distinctValues} // Show distinct values for the selected key
              value={item.value}
              onInputChange={(e, newValue) =>
                handleAboutMeChange(index, "value", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Value"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: isMobile ? "16px" : "14px" }}>
                Visibility
              </InputLabel>
              <Select
                value={item.visibility}
                onChange={(e) =>
                  handleAboutMeChange(index, "visibility", e.target.value)
                }
                style={{ fontSize: isMobile ? "16px" : "14px" }} // Mobile-friendly select size
              >
                {visibilityOptions.map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}>
            <IconButton onClick={() => removeAboutMeField(index)}>
              <RemoveCircleOutlineIcon color="secondary" />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={addAboutMeField}
        color="primary"
        style={{ marginTop: "10px", fontSize: isMobile ? "16px" : "14px" }} // Mobile-friendly button
      >
        Add About Me Attribute
      </Button>

      {/* Drag-and-Drop Photo Section */}
      <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
        Upload Photos (Drag & Drop)
      </Typography>

      <div
        {...getRootProps({
          className: "dropzone",
          style: {
            border: "2px dashed gray",
            padding: "20px",
            textAlign: "center",
            marginBottom: "20px",
            fontSize: isMobile ? "16px" : "14px", // Mobile-friendly dropzone size
          },
        })}
      >
        <input {...getInputProps()} />
        <p>Drag & drop photos here, or click to select files</p>
      </div>

      <Grid container spacing={2}>
        {photos.map((photo, index) => (
          <Grid item xs={12} sm={3} key={index}>
            <img
              src={photo.base64}
              alt={`Uploaded ${index + 1}`}
              width="100"
              style={{ margin: "10px" }}
            />
            {/* Visibility dropdown for each photo */}
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: isMobile ? "16px" : "14px" }}>
                Photo Visibility
              </InputLabel>
              <Select
                value={photo.visibility}
                onChange={(e) =>
                  handlePhotoVisibilityChange(index, e.target.value)
                }
                style={{ fontSize: isMobile ? "16px" : "14px" }} // Mobile-friendly select size
              >
                {visibilityOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Delete Photo Button */}
            <IconButton
              onClick={() => handleDeletePhoto(index)}
              color="secondary"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        ))}
      </Grid>

      {/* Submit Button */}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit}
        style={{ marginTop: "20px", fontSize: isMobile ? "16px" : "14px" }} // Mobile-friendly button
      >
        Save Profile
      </Button>
    </Container>
  );
};

export default ProfileForm;
