import React, { useState, useEffect, Suspense, useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline, Box, CircularProgress } from "@mui/material";

import Sidebar from "./components/Sidebar";
import { WebSocketProvider } from "./components/WebSocketContext";

import ProfileForm from "./components/ProfileForm";
import PreferencesForm from "./components/PreferencesForm";
import UserProfile from "./components/UserProfile";
import MatchingProfiles from "./components/MatchingProfiles";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Messages from "./components/Messages";
import AuthProvider, { AuthContext } from "./components/AuthProvider"; // Import AuthProvider

const App = () => {
  const [mode, setMode] = useState("dark"); // Manage dark/light mode
  const [language, setLanguage] = useState("en"); // Default language is set to English

  // Create a theme based on the mode
  const theme = createTheme({
    typography: {
      fontSize: 12,
      h1: { fontSize: "2rem" },
      h2: { fontSize: "1.75rem" },
      h3: { fontSize: "1.5rem" },
    },
    palette: {
      mode,
    },
  });

  // Function to change language
  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem("language", lang); // Store the selected language in localStorage
  };

  return (
    <Suspense fallback={<CircularProgress />}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <AppRoutes mode={mode} setMode={setMode} />
        </AuthProvider>
      </ThemeProvider>
    </Suspense>
  );
};

// Define AppRoutes to handle authentication and routing
const AppRoutes = ({ mode, setMode }) => {
  const { isAuthenticated, logout } = useContext(AuthContext); // Access AuthContext
  const [roles, setRoles] = useState(["user"]); // Define roles

  // Handle user login and set roles accordingly
  const handleLogin = (roles) => {
    setRoles(roles);
  };

  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        {/* Render Sidebar only if authenticated */}
        {isAuthenticated && (
          <Sidebar mode={mode} setMode={setMode} roles={roles} logout={logout} />
        )}
        <Box component="main" sx={{ flexGrow: 1, p: 3, ml: { xs: 0, sm: isAuthenticated ? "240px" : 0 }, mt: 8 }}>
          <WebSocketProvider>
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<Login onLogin={handleLogin} />} />
              <Route path="/signup" element={<Signup />} />

              {/* Protected Routes */}
              {isAuthenticated ? (
                <>
                  <Route path="/profile" element={<ProfileForm />} />
                  <Route path="/preferences" element={<PreferencesForm />} />
                  <Route path="/matches" element={<MatchingProfiles />} />
                  <Route path="/messages" element={<Messages />} />
                  <Route path="/userprofile" element={<UserProfile />} />
                 
                </>
              ) : (
                <Route path="*" element={<Navigate to="/login" />} /> 
              )}
            </Routes>
          </WebSocketProvider>
        </Box>
      </Box>
    </Router>
  );
};

export default App;
