import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const UserProfile = () => {
  const [userId, setUserId] = useState(""); // Store the user ID input
  const [userData, setUserData] = useState(null); // Store the fetched user data
  const [matchingProfiles, setMatchingProfiles] = useState([]); // Store matching profiles
  const [errorMessage, setErrorMessage] = useState(""); // Store error message

  // Fetch user data by ID
  const fetchUserData = () => {
    fetch(`/mnm-api/users/${userId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("User not found");
        }
      })
      .then((data) => {
        setUserData(data); // Set the fetched user data
        setErrorMessage(""); // Clear any previous errors
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
        setUserData(null); // Clear previous data if there's an error
        setErrorMessage(error.message);
      });
  };

  // Fetch matching profiles by user ID
  const fetchMatchingProfiles = () => {
    fetch(`/mnm-api/matchmaking/matches/${userId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch matching profiles");
        }
      })
      .then((data) => {
        setMatchingProfiles(data); // Set matching profiles
      })
      .catch((error) => {
        console.error("Error fetching matching profiles:", error);
        setErrorMessage(error.message);
      });
  };

  // Fetch matching profiles when user data is fetched
  useEffect(() => {
    if (userId) {
      fetchMatchingProfiles();
    }
  }, [userId]);

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Fetch User Profile
      </Typography>

      {/* User ID input */}
      <TextField
        label="User ID"
        variant="outlined"
        fullWidth
        margin="normal"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
      />

      {/* Fetch button */}
      <Button variant="contained" color="primary" onClick={fetchUserData}>
        Fetch Profile
      </Button>

      {/* Display error message if any */}
      {errorMessage && (
        <Typography variant="body1" color="error" style={{ marginTop: "20px" }}>
          {errorMessage}
        </Typography>
      )}

      {/* Display matching profiles if available */}
      {matchingProfiles.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <Typography variant="h6" gutterBottom>
            Matching Profiles
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {matchingProfiles.map((profile, index) => (
                  <TableRow key={index}>
                    <TableCell>{profile.userId}</TableCell>
                    <TableCell>{profile.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {/* Display user profile if available */}
      {userData && (
        <div style={{ marginTop: "20px" }}>
          <Typography variant="h6" gutterBottom>
            User Profile
          </Typography>
          <Typography>Name: {userData.name}</Typography>
          <Typography>Age: {userData.age}</Typography>
          <Typography>Email: {userData.email}</Typography>

          {/* Display About Me section */}
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            About Me
          </Typography>
          {userData.aboutMe.map((about, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={4}>
                <Typography>Key: {about.key}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>Value: {about.value}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>Visibility: {about.visibility}</Typography>
              </Grid>
            </Grid>
          ))}

          {/* Display photos */}
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Profile Photos
          </Typography>
          <Grid container spacing={2}>
            {userData.profilePhotos.map((photo, index) => (
              <Grid item xs={4} key={index}>
                <img
                  src={photo.photoUrl}
                  alt={`User Photo ${index + 1}`}
                  width="100%"
                  style={{ marginBottom: "10px" }}
                />
                <Typography>Visibility: {photo.visibility}</Typography>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </Container>
  );
};

export default UserProfile;
